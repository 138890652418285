import { Box, Button, Stack, Text, Image, Heading } from "@chakra-ui/react"
import React, { useState } from "react"
import { BiMapPin } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import { AnimatePresence, motion } from "framer-motion"

type Props = {
  facilities: any
  map: any
  open: boolean
  setOpen: any
}

export const SelectedPropertySpotMenu: React.FC<Props> = ({
  facilities,
  map,
  open,
  setOpen,
}) => {
  const ZOOM_LEVEL = 16
  const [flying, setFlying] = useState<boolean>(false)

  const container = {
    hidden: { y: -2000 },
    visible: {
      y: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  }

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  const jumpIntoArea = (area: any) => {
    setFlying(true)

    if (area.location.lat && area.location.lon) {
      map.locate().on("locationfound", function (ev: any) {
        map
          .flyTo([area.location.lat, area.location.lon], ZOOM_LEVEL)
          .on("moveend", function () {
            setFlying(false)
          })
      })
    } else {
      setFlying(false)
    }
  }

  return (
    <>
      <Box
        as={motion.div}
        onClick={() => setOpen(!open)}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        bg={`gray.100`}
        shadow={`md`}
        h={20}
        w={20}
        cursor={`pointer`}
      >
        <Stack
          justify="center"
          align="center"
          h={`100%`}
          w={`100%`}
          p={0}
          position={`relative`}
        >
          <Text fontSize={12}>周辺施設</Text>
          <BiMapPin size={20} />
          <AnimatePresence>
            {open && (
              <Box
                as={motion.div}
                initial={{ opacity: 0, scale: 1.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                position={`absolute`}
                bottom={2}
                right={2}
                w={2}
                h={2}
                rounded={`full`}
                bg={`black`}
              />
            )}
          </AnimatePresence>
        </Stack>
      </Box>
      <AnimatePresence>
        {open && (
          <Stack
            as={motion.div}
            variants={container}
            initial={`hidden`}
            animate={`visible`}
            exit={`hidden`}
            bg={`blackAlpha.700`}
            position={`absolute`}
            top={0}
            left={24}
            h={`80vh`}
            overflowY={`scroll`}
            w={`400px`}
            p={8}
            shadow={`md`}
            spacing={4}
            css={{
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                width: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#777",
                borderRadius: "24px",
              },
            }}
          >
            <Heading color={`white`} fontSize={16} mb={2}>
              選択中の物件の周辺施設
            </Heading>
            {facilities ? (
              <>
                {facilities.map((facility: any) => (
                  <Stack
                    key={facility.id}
                    onClick={() => jumpIntoArea(facility)}
                    as={motion.button}
                    variants={item}
                    bg={`gray.100`}
                    direction={`row`}
                    spacing={0}
                    rounded={`5px`}
                  >
                    <Image
                      h={24}
                      w={`150px`}
                      roundedLeft={`5px`}
                      objectFit={`cover`}
                      src={facility.featuredPhoto.file.url}
                    />
                    <Stack p={4}>
                      <Text
                        fontSize={16}
                        textAlign={`start`}
                        fontWeight={`bold`}
                      >
                        {facility.name}
                      </Text>
                    </Stack>
                  </Stack>
                ))}
              </>
            ) : (
              <Text color={`white`}>周辺施設がありません</Text>
            )}
          </Stack>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {open && (
          <Button
            as={motion.button}
            onClick={setOpen}
            initial={{ opacity: 0, y: -2000 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -2000 }}
            _hover={{}}
            whileHover={{ scale: 1.2 }}
            position={`absolute`}
            top={0}
            right={`-470px`}
            zIndex={75}
            size={`sm`}
            rounded={0}
            bg={`black`}
            p={0}
            color={`white`}
          >
            <MdClose />
          </Button>
        )}
      </AnimatePresence>
    </>
  )
}
