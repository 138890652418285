import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react"
import { motion, useMotionValue } from "framer-motion"
import React, { useEffect, useState } from "react"
import { MotionBox } from "./motion-box"
import { HiOutlineMinus } from "react-icons/hi"
import { GrFormClose } from "react-icons/gr"
import { DraggableContainer } from "./draggable-container"
import { SlideImage } from "./slide-image"
import { SlideFacility } from "./slide-facility"
import { navigate } from "gatsby"
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai"

export const PropertyDetailModal: React.FC<any> = ({ map, property }) => {
  const y: any = useMotionValue(0)
  const [onTop, setOnTop] = useState(false)
  const jsonFavorites: any = localStorage.getItem("favorites")
  const favorites = jsonFavorites ? JSON.parse(jsonFavorites) : null

  const isFavorited = favorites
    ? favorites.properties.includes(property.id)
    : false

  const addToFavorite = () => {
    if (favorites) {
      if (!isFavorited) {
        localStorage.setItem(
          "favorites",
          JSON.stringify({
            properties: favorites.properties.concat([property.id]),
          })
        )
      }
    } else {
      localStorage.setItem(
        "favorites",
        JSON.stringify({ properties: [property.id] })
      )
    }
  }

  useEffect(
    () =>
      y.onChange((latestY: number) => {
        if (latestY === -780) {
          setOnTop(true)
        } else {
          setOnTop(false)
        }
      }),
    []
  )

  return (
    <>
      <MotionBox
        drag={onTop ? false : "y"}
        dragConstraints={{ bottom: -780, top: 0 }}
        dragElastic={1}
        style={{ y }}
        bg={`white`}
        shadow={`md`}
        position={`absolute`}
        bottom={-780}
        p={4}
        w={`100%`}
        roundedTop={10}
        h={`100vh`}
        display={`flex`}
        flexDirection={`column`}
        gap={4}
        pt={8}
        zIndex={25}
      >
        <Box position={`absolute`} top={6} right={6}>
          {isFavorited ? (
            <Button
              isDisabled={isFavorited}
              onClick={addToFavorite}
              color={`black`}
              bg={`transparent`}
              p={0}
              _disabled={{ color: "black" }}
              _hover={{ opacity: 0.9 }}
              size={`sm`}
            >
              <AiFillHeart size={20} />
            </Button>
          ) : (
            <Button
              isDisabled={isFavorited}
              onClick={addToFavorite}
              color={`black`}
              bg={`transparent`}
              p={0}
              _hover={{ opacity: 0.9 }}
              size={`sm`}
            >
              <AiOutlineHeart size={20} />
            </Button>
          )}
        </Box>
        <Stack
          as={motion.div}
          bg={`transparent`}
          p={0}
          justify={`center`}
          align={`center`}
          position={`absolute`}
          w={`92%`}
          top={0}
          color={`blackAlpha.600`}
        >
          <HiOutlineMinus size={30} />
        </Stack>
        {onTop && (
          <Button
            position={`absolute`}
            size={`xs`}
            bg={`transparent`}
            top={2}
            right={2}
            p={0}
            onClick={() => y.set(0)}
            _pressed={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            <GrFormClose size={20} />
          </Button>
        )}
        <Stack>
          <Heading fontSize={16} color={`gray.800`}>
            {property.propertyName}
          </Heading>
          <Text fontSize={12}>{property.propertyCategory.name}</Text>
        </Stack>
        <Stack overflowX={`hidden`}>
          {/* <DraggableContainer>
          {options.map((option, i) => (
            <OptionBox key={option.id} text={option.name} />
          ))}
        </DraggableContainer> */}
        </Stack>
        <Stack overflowX={`hidden`}>
          <DraggableContainer>
            {property.images.map((image: any, i: number) => (
              <SlideImage
                key={i}
                text={`${property.propertyName}-${i}`}
                src={image.file.url}
              />
            ))}
          </DraggableContainer>
        </Stack>
        <Stack py={4} spacing={4}>
          <Stack>
            <Heading fontSize={14}>ワンポイント</Heading>
            <Text fontSize={13} lineHeight={2}>
              {property.comment.comment}
            </Text>
          </Stack>
          <Stack>
            <Heading fontSize={14}>価格</Heading>
            <Text fontSize={13}>{property.price}万円</Text>
          </Stack>
          <Stack>
            <Heading fontSize={14}>住所</Heading>
            <Text fontSize={13}>{property.adress}</Text>
          </Stack>
          <Stack>
            <Heading fontSize={14}>交通</Heading>
            <Text fontSize={13}>{property.transportation}</Text>
          </Stack>
        </Stack>
        <Button
          size={`sm`}
          onClick={() => navigate(`/properties/${property.slug}`)}
        >
          詳細を見る
        </Button>
        <Heading fontSize={16}>周辺施設</Heading>
        {property.facilities ? (
          <Stack overflowX={`hidden`}>
            <DraggableContainer>
              {property.facilities.map((facilitiy: any) => (
                <SlideFacility
                  key={facilitiy.id}
                  text={facilitiy.name}
                  description={facilitiy.description.description}
                  src={facilitiy.featuredPhoto.file.url}
                />
              ))}
            </DraggableContainer>
          </Stack>
        ) : (
          <Text fontSize={12}>周辺施設の登録がありません</Text>
        )}
      </MotionBox>
    </>
  )
}
