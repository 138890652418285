import { Box, Heading, Image, Input, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { HiSearch } from "react-icons/hi"

const { normalize } = require("@geolonia/normalize-japanese-addresses")

type FormData = {
  address: string
}

type NomalizedLocation = {
  lat: number
  lng: number
}

export const SearchBox: React.FC<any> = ({ map }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    const { address } = data

    return new Promise(resolve => {
      setTimeout(() => {
        normalize(address).then((res: NomalizedLocation) => {
          const { lat, lng } = res

          if (lat && lng) {
            return map
              .flyTo([lat, lng], map.getZoom())
              .on("moveend", function () {
                resolve("ok")
              })
          }

          resolve("ng")
        })
      }, 1000)
    })
  }

  return (
    <Stack position={`relative`}>
      <Box position="absolute" top={5} left={3} color={`blackAlpha.600`}>
        <HiSearch size={18} />
      </Box>
      <Box as={`form`} onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register(`address`)}
          border={0}
          bg={`blackAlpha.50`}
          w={`100%`}
          shadow={`md`}
          rounded={0}
          fontSize={14}
          pl={10}
          _focus={{ border: 0 }}
          _placeholder={{ fontSize: 14 }}
          placeholder={`住所を入力してください`}
        />
      </Box>
    </Stack>
  )
}
