import { Button, Text } from "@chakra-ui/react"
import React, { useState } from "react"

export const AreaJumpButton: React.FC<any> = ({ area, map }) => {
  const ZOOM_LEVEL = 13
  const [flying, setFlying] = useState<boolean>(false)

  const jumpIntoArea = (area: any) => {
    setFlying(true)

    if (area.location.lat && area.location.lon) {
      map.locate().on("locationfound", function (ev: any) {
        map
          .flyTo([area.location.lat, area.location.lon], ZOOM_LEVEL)
          .on("moveend", function () {
            setFlying(false)
          })
      })
    } else {
      setFlying(false)
    }
  }

  return (
    <Button
      isLoading={flying}
      onClick={() => jumpIntoArea(area)}
      size={`xs`}
      colorScheme={`gray`}
      flexShrink={0}
      rounded={`full`}
    >
      <Text fontSize={12}>{area.name}</Text>
    </Button>
  )
}
