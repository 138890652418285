import { Button, Heading, Stack, Text, Image } from "@chakra-ui/react"
import { motion, useMotionValue } from "framer-motion"
import React, { useEffect, useState } from "react"
import { MotionBox } from "./motion-box"
import { HiOutlineMinus } from "react-icons/hi"
import { GrFormClose } from "react-icons/gr"
import { DraggableContainer } from "./draggable-container"

export const FacilityDetailModal: React.FC<any> = ({ map, facility }) => {
  const y: any = useMotionValue(0)
  const [onTop, setOnTop] = useState(false)

  useEffect(
    () =>
      y.onChange((latestY: number) => {
        if (latestY === -780) {
          setOnTop(true)
        } else {
          setOnTop(false)
        }
      }),
    []
  )

  return (
    <>
      <MotionBox
        drag={onTop ? false : "y"}
        dragConstraints={{ bottom: -780, top: 0 }}
        dragElastic={1}
        style={{ y }}
        bg={`white`}
        shadow={`md`}
        position={`absolute`}
        bottom={-780}
        p={4}
        w={`100%`}
        roundedTop={10}
        h={`100vh`}
        display={`flex`}
        flexDirection={`column`}
        gap={4}
        pt={8}
        zIndex={25}
      >
        <Stack
          as={motion.div}
          bg={`transparent`}
          p={0}
          justify={`center`}
          align={`center`}
          position={`absolute`}
          w={`92%`}
          top={0}
          color={`blackAlpha.600`}
        >
          <HiOutlineMinus size={30} />
        </Stack>
        {onTop && (
          <Button
            position={`absolute`}
            size={`xs`}
            bg={`transparent`}
            top={2}
            right={2}
            p={0}
            onClick={() => y.set(0)}
            _pressed={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            <GrFormClose size={20} />
          </Button>
        )}
        <Stack>
          <Heading fontSize={16} color={`gray.800`}>
            {facility.name}
          </Heading>
          <Text fontSize={12}>{facility.description.description}</Text>
        </Stack>
        <Stack overflowX={`hidden`}>
          <DraggableContainer>
            <Stack
              bg={`green.50`}
              w={48}
              h={48}
              flexShrink={0}
              justify="center"
              align="center"
              rounded={10}
            >
              <Image
                src={facility.featuredPhoto.file.url}
                alt={`featuredPhoto`}
                w={48}
                h={48}
                rounded={10}
                objectFit={`cover`}
              />
            </Stack>
          </DraggableContainer>
        </Stack>
      </MotionBox>
    </>
  )
}
