import React, { useState } from "react"

import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Stack,
  Image,
  Tag,
  Spacer,
} from "@chakra-ui/react"
import { AiFillHeart } from "react-icons/ai"
import { RiDeleteBin7Fill } from "react-icons/ri"

type Props = {
  properties: any[]
}

export const FavoritesModal: React.FC<Props> = ({ properties }) => {
  const [favorites, setFavorites] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = () => {
    onOpen()

    const jsonFavorites: any = localStorage.getItem("favorites")
    const favorites = jsonFavorites ? JSON.parse(jsonFavorites) : null

    if (favorites) {
      const faPrs = favorites.properties.map(
        (pId: string) => properties.filter(({ node: p }) => p.id === pId)[0]
      )

      setFavorites(faPrs)
    }
  }

  const removePropertyFromFavorite = (id: string) => {
    const jsonFavorites: any = localStorage.getItem("favorites")
    const fav = jsonFavorites ? JSON.parse(jsonFavorites) : null
    const removedFaPrs = fav.properties.filter((prId: string) => prId !== id)

    localStorage.setItem(
      "favorites",
      JSON.stringify({ properties: removedFaPrs })
    )

    const prs = favorites.filter(({ node: pr }: any) => pr.id !== id)

    setFavorites(prs)
  }

  return (
    <>
      <Button size={`sm`} onClick={openModal}>
        <Stack direction={`row`} justify={`center`} align={`center`}>
          <AiFillHeart size={14} />
          <Text>お気に入りを見る</Text>
        </Stack>
      </Button>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={`lg`}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          bg={`gray.50`}
          h={`700px`}
          overflowY={`scroll`}
          zIndex={75}
        >
          <ModalHeader>お気に入り物件リスト</ModalHeader>
          <ModalCloseButton />
          <ModalBody zIndex={75}>
            {favorites ? (
              <Stack spacing={6}>
                {favorites.map(({ node: pr }: any) => {
                  return (
                    <Stack
                      key={pr.id}
                      direction={`row`}
                      shadow={`sm`}
                      bg={`white`}
                      p={2}
                      rounded={5}
                      w={`100%`}
                    >
                      <Image
                        src={pr.images[0].file.url}
                        h={24}
                        w={`150px`}
                        flexShrink={0}
                        objectFit={`cover`}
                        rounded={5}
                      />
                      <Stack p={1} spacing={1} align={`start`} w={`100%`}>
                        <Text fontSize={14} fontWeight={`bold`}>
                          {pr.propertyName}
                        </Text>
                        <Text fontSize={12}>{pr.adress}</Text>
                        <Text
                          fontSize={14}
                          color={`blue.700`}
                          fontWeight={`bold`}
                        >
                          {pr.price}万円
                        </Text>
                        <Stack direction={`row`} w={`100%`}>
                          <Tag fontSize={12}>{pr.layout}</Tag>
                          <Spacer />
                          <Button
                            onClick={() => removePropertyFromFavorite(pr.id)}
                            size={`xs`}
                            bg={`pink`}
                            p={0}
                          >
                            <RiDeleteBin7Fill size={15} />
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                })}
              </Stack>
            ) : (
              <Text>お気に入り物件はありません</Text>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
