import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { TiLocationArrowOutline } from "react-icons/ti"

export const CurrentLocationButton: React.FC<any> = ({ map }) => {
  const ZOOM_LEVEL = 16
  const [flying, setFlying] = useState<boolean>(false)

  return (
    <Button
      onClick={() => {
        setFlying(true)

        map.locate().on("locationfound", function (ev: any) {
          map.flyTo(ev.latlng, ZOOM_LEVEL).on("moveend", function () {
            setFlying(false)
          })
        })
      }}
      isLoading={flying}
      position={`absolute`}
      bottom={24}
      right={4}
      p={0}
      rounded={`full`}
    >
      <TiLocationArrowOutline size={20} />
    </Button>
  )
}
