import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  useDisclosure,
  Image,
  Text,
  Tag,
  Spacer,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { RiDeleteBin7Fill } from "react-icons/ri"

export const FavoritesModal: React.FC<any> = ({ properties }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [favorites, setFavorites] = useState<any>(null)

  const openFavorites = () => {
    onOpen()

    const jsonFavorites: any = localStorage.getItem("favorites")
    const favorites = jsonFavorites ? JSON.parse(jsonFavorites) : null

    if (favorites) {
      const faPrs = favorites.properties.map(
        (pId: string) =>
          properties.filter(({ node: p }: any) => p.id === pId)[0]
      )

      setFavorites(faPrs)
    }
  }

  const removePropertyFromFavorite = (id: string) => {
    const jsonFavorites: any = localStorage.getItem("favorites")
    const fav = jsonFavorites ? JSON.parse(jsonFavorites) : null
    const removedFaPrs = fav.properties.filter((prId: string) => prId !== id)

    localStorage.setItem(
      "favorites",
      JSON.stringify({ properties: removedFaPrs })
    )

    const prs = favorites.filter(({ node: pr }: any) => pr.id !== id)

    setFavorites(prs)
  }

  return (
    <>
      <Button
        onClick={openFavorites}
        size={`xs`}
        rounded={`full`}
        colorScheme={`pink`}
      >
        お気に入り
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} size={`full`}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>お気に入り</DrawerHeader>
          <DrawerBody>
            {favorites ? (
              <Stack spacing={6}>
                {favorites.map(({ node: pr }: any) => {
                  return (
                    <Stack
                      key={pr.id}
                      direction={`row`}
                      shadow={`sm`}
                      bg={`white`}
                      p={2}
                      rounded={5}
                      w={`100%`}
                    >
                      <Image
                        src={pr.images[0].file.url}
                        h={`100%`}
                        w={32}
                        flexShrink={0}
                        objectFit={`cover`}
                        rounded={5}
                      />
                      <Stack p={1} spacing={1} align={`start`} w={`100%`}>
                        <Text fontSize={14} fontWeight={`bold`}>
                          {pr.propertyName}
                        </Text>
                        <Text fontSize={12}>{pr.adress}</Text>
                        <Text
                          fontSize={14}
                          color={`blue.700`}
                          fontWeight={`bold`}
                        >
                          {pr.price}万円
                        </Text>
                        <Stack direction={`row`} w={`100%`}>
                          <Tag fontSize={12}>{pr.layout}</Tag>
                          <Spacer />
                          <Button
                            onClick={() => removePropertyFromFavorite(pr.id)}
                            size={`xs`}
                            bg={`pink`}
                            p={0}
                          >
                            <RiDeleteBin7Fill size={15} />
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                })}
              </Stack>
            ) : (
              <Text>お気に入り物件はありません</Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
