import { motion, useMotionValue } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { MotionBox } from "./motion-box";

export const DraggableContainer: React.FC<any> = ({ children }) => {
  const ref: any = useRef();
  const x: any = useMotionValue(0);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
  const [sliderConstraints, setSliderConstraints] = useState(0);

  useEffect(() => {
    if (!ref && !ref.current) return;

    const calcSliderChildrenWidth = () => {
      setSliderChildrenWidth(ref?.current?.scrollWidth);
    };

    calcSliderChildrenWidth();

    const calcSliderWidth = () => {
      setSliderWidth(ref?.current?.clientWidth);
    };

    calcSliderWidth();
    window.addEventListener("resize", calcSliderWidth);

    const calcSliderConstraints = () => {
      setSliderConstraints(sliderChildrenWidth - sliderWidth);
    };

    calcSliderConstraints();
    window.addEventListener("resize", calcSliderConstraints);
  }, [ref, sliderChildrenWidth, sliderWidth]);

  return (
    <MotionBox
      as={motion.div}
      ref={ref}
      initial={{ x: 0 }}
      style={{ x }}
      drag={`x`}
      dragConstraints={{ left: -sliderConstraints - 15, right: 0 }}
      dragTransition={{ bounceStiffness: 100, bounceDamping: 10 }}
      display={`flex`}
      gap={4}
      cursor={`grab`}
      bg={`white`}
    >
      {children}
    </MotionBox>
  );
};
