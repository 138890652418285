import { Button, Stack } from "@chakra-ui/react"
import { motion } from "framer-motion"
import React from "react"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

export const ZoomInOut: React.FC<any> = ({ map }) => {
  const zoomIn = () => {
    const currentZoom = map.getZoom()
    map.setZoom(currentZoom + 1)
  }

  const zoomOut = () => {
    const currentZoom = map.getZoom()
    map.setZoom(currentZoom - 1)
  }

  return (
    <Stack w={8} position={`absolute`} bottom={12} left={6}>
      <Button
        as={motion.button}
        whileHover={{ scale: 1.2 }}
        _hover={{}}
        onClick={zoomIn}
        h={10}
        w={10}
        p={0}
        bg={`black`}
        color={`white`}
        rounded={0}
      >
        <AiOutlinePlus />
      </Button>
      <Button
        as={motion.button}
        whileHover={{ scale: 1.2 }}
        _hover={{}}
        onClick={zoomOut}
        h={10}
        w={10}
        p={0}
        bg={`black`}
        color={`white`}
        rounded={0}
      >
        <AiOutlineMinus />
      </Button>
    </Stack>
  )
}
