import { useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { graphql } from "gatsby"
import { SearchInMapDesktop } from "../components/map/search-in-map-desktop"
import { SearchInMapSp } from "../components/sp-map/search-in-map-sp"
import { MapGraphQLData } from "../types/map-graph-ql-data"
import Seo from "../components/seo"

const SearchInMap: React.FC<MapGraphQLData> = ({ data }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: true,
    lg: true,
    xl: false,
  })

  return (
    <>
      <Seo title="エリアから探す" description="エリアから探す" />
      {isMobile ? (
        <SearchInMapSp data={data} />
      ) : (
        <SearchInMapDesktop data={data} />
      )}
    </>
  )
}

export default SearchInMap

export const query = graphql`
  {
    allContentfulFacilityTypes {
      edges {
        node {
          id
          name
          slug
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    allContentfulAreas {
      edges {
        node {
          id
          name
          slug
          location {
            lat
            lon
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    allContentfulFacilities {
      edges {
        node {
          description {
            description
          }
          id
          featuredPhoto {
            file {
              url
            }
          }
          location {
            lat
            lon
          }
          name
          facilityType {
            slug
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    allContentfulProperties (
      filter: { status: { lt: 7 } }
    ) {
      edges {
        node {
          id
          propertyName
          propertyLocation {
            lat
            lon
          }
          slug
          propertyCategory {
            name
          }
          transportation
          adress
          images {
            file {
              url
            }
          }
          layout
          comment {
            comment
          }
          price
          structure
          buildingSpaceSqm
          facilities {
            id
            name
            location {
              lat
              lon
            }
            description {
              description
            }
            featuredPhoto {
              file {
                url
              }
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`
