import { Box, Button, Input, Stack } from "@chakra-ui/react"
import { HiSearch } from "react-icons/hi"
import { motion } from "framer-motion"
import React from "react"
import { useForm } from "react-hook-form"

type FormData = {
  address: string
}

type NomalizedLocation = {
  lat: number
  lng: number
}

const { normalize } = require("@geolonia/normalize-japanese-addresses")

export const MapSearchBox: React.FC<any> = ({ map }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    const { address } = data

    return new Promise(resolve => {
      setTimeout(() => {
        normalize(address).then((res: NomalizedLocation) => {
          const { lat, lng } = res

          if (lat && lng) {
            return map
              .flyTo([lat, lng], map.getZoom())
              .on("moveend", function () {
                resolve("ok")
              })
          }

          resolve("ng")
        })
      }, 1000)
    })
  }

  return (
    <Stack
      as={motion.div}
      initial={{ opacity: 0, x: -400 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.5 } }}
      direction="row"
      position={`relative`}
    >
      <Box position="absolute" top={3} left={5} color={`blackAlpha.600`}>
        <HiSearch size={18} />
      </Box>
      <Box as={`form`} onSubmit={handleSubmit(onSubmit)}>
        <Input
          as={motion.input}
          {...register(`address`)}
          whileFocus={{ width: "400px" }}
          border={0}
          bg={`blackAlpha.50`}
          w={`300px`}
          shadow={`md`}
          rounded={0}
          pl={10}
          _focus={{ border: 0 }}
          _placeholder={{ fontSize: 14 }}
          placeholder={`住所を入力してください`}
        />
        <Button
          as={motion.button}
          type={`submit`}
          isLoading={isSubmitting}
          bg={`black`}
          color={`white`}
          rounded={0}
          whileHover={{
            scale: 1.2,
            transition: { type: "spring", stiffness: 100, duration: 0.1 },
          }}
          fontSize={14}
          _hover={{}}
        >
          検索
        </Button>
      </Box>
    </Stack>
  )
}
