import { Heading, Link, Spacer, Stack, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { Link as GL } from "gatsby"
import { FiExternalLink } from "react-icons/fi"

export const MapHeader: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Stack
      as={`header`}
      p={4}
      px={6}
      h={20}
      shadow={`md`}
      direction={`row`}
      justify="center"
      align="center"
      position={`relative`}
      zIndex={75}
      bg={`white`}
    >
      <Heading as={`h1`}>
        <Link
          as={GL}
          to={`/`}
          _hover={{ textDecoration: "none", opacity: 0.5 }}
        >
          <Text
            mb={2}
            fontSize={18}
            textAlign={"center"}
            fontWeight={`thin`}
            fontFamily={"serif"}
          >
            サイファーム南伊豆
          </Text>
          <Text
            textAlign={`center`}
            fontSize={10}
            letterSpacing={1.6}
            fontWeight={`bold`}
            fontFamily={"inter"}
            textTransform={`uppercase`}
            whiteSpace="nowrap"
          >
            SaiFarm Minami-Izu
          </Text>
        </Link>
      </Heading>
      <Spacer />
      {children}
      <Spacer />
      <Link
        as={GL}
        to={`/properties/property-categories/house`}
        _hover={{ textDecoration: "underline" }}
      >
        <Stack direction="row" spacing={1} justify="center" align="center">
          <Text fontSize={12}>
            物件を一覧で表示
          </Text>
          <FiExternalLink size={14} />
        </Stack>
      </Link>
    </Stack>
  )
}
