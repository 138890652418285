import { Heading, Image, Stack, Text } from "@chakra-ui/react"
import React from "react"

export const SlideFacility: React.FC<any> = ({ text, description, src }) => {
  return (
    <Stack
      bg={`blackAlpha.50`}
      w={`300px`}
      h={32}
      flexShrink={0}
      direction={`row`}
      rounded={10}
      shadow={`lg`}
    >
      <Image
        src={src}
        alt={text}
        w={32}
        h={32}
        roundedLeft={10}
        objectFit={`cover`}
        flexShrink={0}
      />
      <Stack p={4} px={2}>
        <Heading fontSize={14}>{text}</Heading>
        <Text fontSize={12} lineHeight={2}>
          {description}
        </Text>
      </Stack>
    </Stack>
  )
}
