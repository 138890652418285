import {
  Button,
  Divider,
  Heading,
  Image,
  Spacer,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { MdClose } from "react-icons/md"
import { motion } from "framer-motion"
import { navigate } from "gatsby-link"
import { FavoritesModal } from "./favorites-modal"

type Props = {
  property: {
    id: string
    slug: string
    propertyName: string
    propertyLocation: {
      lat: number
      lon: number
    }
    propertyCategory: {
      name: string
    }
    images: {
      file: {
        url: string
      }
    }[]
    layout: string
    comment: {
      comment: string
    }
    adress: string
    transportation: string
    price: number
    buildingSpaceSqm: number
  }
  setSelectedProperty: any
  properties: any[]
}

export const PropertyPane: React.FC<Props> = ({
  property,
  setSelectedProperty,
  properties,
}) => {
  const container = {
    hidden: { opacity: 0, width: 0 },
    visible: {
      opacity: 1,
      width: `500px`,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  }

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  return (
    <Stack
      as={motion.div}
      initial={`hidden`}
      animate={`visible`}
      variants={container}
      exit={`hidden`}
      position={`absolute`}
      align={`start`}
      right={0}
      top={24}
      zIndex={75}
      h={`90vh`}
      bg={`white`}
      shadow={`md`}
      spacing={8}
      p={8}
    >
      <Button
        as={motion.button}
        onClick={() => setSelectedProperty("")}
        _hover={{}}
        whileHover={{ scale: 1.2 }}
        position={`absolute`}
        size={`sm`}
        p={0}
        rounded={0}
        top={0}
        left={-12}
        zIndex={75}
        bg={`black`}
        color={`white`}
      >
        <MdClose />
      </Button>
      <Stack shadow={`sm`} p={4} as={motion.div} variants={item}>
        <Heading fontSize={20} pb={4}>
          選択中の物件
        </Heading>
        <Heading fontSize={14}>{property.propertyName}</Heading>
        <Stack direction={`row`}>
          <Image src={property.images[0].file.url} w={32} />
          <Stack align={`start`}>
            <Tag size={`sm`}>{property.propertyCategory.name}</Tag>
            <Text fontSize={12}>{property.transportation}</Text>
            <Text fontSize={12}>{property.adress}</Text>
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Text fontSize={18} fontWeight={`bold`}>
            {property.price}万円
          </Text>
          <Stack direction={`row`}>
            <Stack direction={`row`} w={`50%`}>
              <Text fontWeight={`bold`} fontSize={12}>
                間取り
              </Text>
              <Text fontSize={12}>{property.layout || "-"}</Text>
            </Stack>
            <Spacer />
            <Stack direction={`row`} w={`50%`}>
              <Text fontWeight={`bold`} fontSize={12}>
                専有面積
              </Text>
              <Text fontSize={12}>{property.buildingSpaceSqm || "-"}</Text>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Button
          size={`sm`}
          onClick={() => navigate(`/properties/${property.slug}`)}
        >
          詳細を見る
        </Button>
      </Stack>
      <FavoritesModal properties={properties} />
    </Stack>
  )
}
