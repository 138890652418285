import { Image, Stack, Text } from "@chakra-ui/react"
import React from "react"

export const SlideImage: React.FC<any> = ({ text, src }) => {
  return (
    <Stack
      bg={`green.50`}
      w={48}
      h={48}
      flexShrink={0}
      justify="center"
      align="center"
      rounded={10}
    >
      <Image
        src={src}
        alt={text}
        w={48}
        h={48}
        rounded={10}
        objectFit={`cover`}
      />
    </Stack>
  )
}
