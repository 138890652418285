import {
  Box,
  Button,
  Input,
  Spacer,
  Stack,
  Text,
  useOutsideClick,
} from "@chakra-ui/react"
import React, { useMemo, useRef, useState } from "react"
import Seo from "../seo"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import L from "leaflet"

import "../../styles/map.css"
import { DraggableContainer } from "./draggable-container"
import { AreaJumpButton } from "./area-jump-button"
import { FavoritesModal } from "./farovites-modal"
import { CategoryBox } from "./category-box"
import { PropertyDetailModal } from "./property-detail"
import { MapGraphQLData } from "../../types/map-graph-ql-data"
import { SearchBox } from "./search-box"
import { CurrentLocationButton } from "./current-location-button"
import { BlueIcon } from "../icons/blue-icon"
import { RedIcon } from "../icons/red-icon"
import { FacilityDetailModal } from "./facility-detail"
import { AtmIcon } from "../icons/atm-icon"
import { getIconByCategory } from "../icons/get-icon-by-category"

export const SearchInMapSp: React.FC<MapGraphQLData> = ({ data }) => {
  let displayMap
  const center: any = [34.6944445, 138.7469657]
  const zoom = 15
  const [map, setMap] = useState<any | null>(null)
  const headerRef: any = useRef()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [showAreas, setShowAreas] = useState<boolean>(false)
  const [selectedPlace, setSelectedPlace] = useState<any | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null)

  const {
    allContentfulAreas: { edges: areas },
    allContentfulFacilityTypes: { edges: facilityTypes },
    allContentfulProperties: { edges: properties },
    allContentfulFacilities: { edges: facilities },
  } = data

  useOutsideClick({
    ref: headerRef,
    handler: () => {
      setShowAreas(false)
      setIsOpen(false)
    },
  })

  if (typeof window !== "undefined") {
    displayMap = useMemo(() => {
      return (
        <MapContainer
          center={center}
          zoom={zoom}
          whenCreated={setMap}
          style={{ height: "100vh" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {properties.map(({ node: property }) => (
            <Marker
              key={property.id}
              position={[
                property.propertyLocation.lat,
                property.propertyLocation.lon,
              ]}
              icon={RedIcon}
              eventHandlers={{
                click: e => {
                  setSelectedPlace(property)
                  if (map) {
                    map.setView(
                      [
                        property.propertyLocation.lat,
                        property.propertyLocation.lon,
                      ],
                      zoom
                    )
                  }
                },
              }}
            ></Marker>
          ))}
          {facilities
            .filter(({ node: facility }) =>
              selectedCategory
                ? facility.facilityType.slug === selectedCategory
                : true
            )
            .map(({ node: facility }) => (
              <Marker
                key={facility.id}
                position={[facility.location.lat, facility.location.lon]}
                icon={getIconByCategory(facility.facilityType.slug)}
                eventHandlers={{
                  click: e => {
                    setSelectedPlace(facility)
                    if (map) {
                      map.setView(
                        [facility.location.lat, facility.location.lon],
                        zoom
                      )
                    }
                  },
                }}
              ></Marker>
            ))}
        </MapContainer>
      )
    }, [selectedPlace, selectedCategory])
  }

  return (
    <Stack
      w={`100%`}
      h={`100vh`}
      spacing={0}
      bg={`blackAlpha.800`}
      overflowY={`hidden`}
      position={`relative`}
    >
      <Stack
        as={`header`}
        ref={headerRef}
        bg={`white`}
        position={`relative`}
        zIndex={25}
        p={4}
        spacing={4}
        onMouseOver={() => setIsOpen(true)}
      >
        {map && <SearchBox map={map} />}
        {isOpen && (
          <>
            {showAreas ? (
              <Stack overflowX={`hidden`}>
                {map && (
                  <DraggableContainer>
                    {areas.map(({ node: area }) => {
                      return (
                        <AreaJumpButton key={area.id} area={area} map={map} />
                      )
                    })}
                  </DraggableContainer>
                )}
              </Stack>
            ) : (
              <Stack direction="row">
                <Button
                  onClick={() => setShowAreas(!showAreas)}
                  size={`xs`}
                  rounded={`full`}
                  colorScheme={`gray`}
                >
                  エリアから探す
                </Button>
                <Spacer />
                <FavoritesModal properties={properties} />
              </Stack>
            )}
          </>
        )}

        <Stack overflowX={`hidden`}>
          <DraggableContainer>
            {facilityTypes.map(({ node: facilityType }) => (
              <CategoryBox
                key={facilityType.id}
                text={facilityType.name}
                slug={facilityType.slug}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            ))}
          </DraggableContainer>
        </Stack>
      </Stack>
      <Box position={`absolute`} w={`100%`} top={0} zIndex={0}>
        {displayMap}
      </Box>
      {map && <CurrentLocationButton map={map} />}
      {selectedPlace && map && (
        <>
          {selectedPlace.sys.contentType.sys.id === "property" && (
            <PropertyDetailModal map={map} property={selectedPlace} />
          )}
          {selectedPlace.sys.contentType.sys.id === "facility" && (
            <FacilityDetailModal map={map} facility={selectedPlace} />
          )}
        </>
      )}
    </Stack>
  )
}
