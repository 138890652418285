import { Box, Spacer, Stack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { MapLayout } from "./layout"
import Seo from "../seo"
import { MapHeader } from "./header"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import L from "leaflet"
import { FlyToLocationButton } from "./fly-to-location-button"
import { MapSearchBox } from "./search-box"
import { ZoomInOut } from "./zoom-in-out"
import { AreaMenu } from "./area-menu"
import { AnimatePresence } from "framer-motion"
import { PropertyPane } from "./property-pane"
import { SpotMenu } from "./spot-menu"

import "../../styles/map.css"
import { MarkerPropertyCard } from "./marker-property-card"
import { MarkerFacilityCard } from "./marker-facility-card"
import { SelectedPropertySpotMenu } from "./selected-property-spot-menu"
import { MapGraphQLData } from "../../types/map-graph-ql-data"
import { RedIcon } from "../icons/red-icon"
import { getIconByCategory } from "../icons/get-icon-by-category"
import { BlueIcon } from "../icons/blue-icon"

export const SearchInMapDesktop: React.FC<MapGraphQLData> = ({ data }) => {
  let displayMap
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null)
  const [openAreaMenu, setOpenAreaMenu] = useState<boolean>(false)
  const [openSpotMenu, setOpenSpotMenu] = useState<boolean>(false)

  const {
    allContentfulAreas: { edges: areas },
    allContentfulProperties: { edges: properties },
    allContentfulFacilities: { edges: facilities },
  } = data

  const center: any = [34.6944445, 138.7469657]
  const zoom = 15
  const [map, setMap] = useState<any | null>(null)

  if (typeof window !== "undefined") {
    displayMap = useMemo(() => {
      const clickPropertyMarker = (property: any) => {
        setSelectedProperty("")
        if (map) {
          map.setView(
            [property.propertyLocation.lat, property.propertyLocation.lon],
            zoom
          )
        }

        setTimeout(() => {
          setSelectedProperty(property.id)
          setOpenSpotMenu(true)
          setOpenAreaMenu(false)
        }, 500)
      }

      return (
        <MapContainer
          center={center}
          zoom={zoom}
          whenCreated={setMap}
          style={{ height: "100vh" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {properties.map(({ node: property }) => (
            <Marker
              position={[
                property.propertyLocation.lat,
                property.propertyLocation.lon,
              ]}
              icon={RedIcon}
              eventHandlers={{
                click: e => clickPropertyMarker(property),
              }}
            >
              <Popup>
                <MarkerPropertyCard property={property} />
              </Popup>
            </Marker>
          ))}
          {selectedProperty ? (
            <>
              {properties.filter(
                ({ node: pr }) => pr.id === selectedProperty
              )[0].node.facilities && (
                <>
                  {properties
                    .filter(({ node: pr }) => pr.id === selectedProperty)[0]
                    .node.facilities.map(facility => (
                      <Marker
                        position={[
                          facility.location.lat,
                          facility.location.lon,
                        ]}
                        icon={BlueIcon}
                      >
                        <MarkerFacilityCard facility={facility} />
                      </Marker>
                    ))}
                </>
              )}
            </>
          ) : (
            <>
              {facilities.map(({ node: facility }) => (
                <Marker
                  position={[facility.location.lat, facility.location.lon]}
                  icon={getIconByCategory(facility.facilityType.slug)}
                  eventHandlers={{
                    click: e => {
                      if (map) {
                        map.setView(
                          [facility.location.lat, facility.location.lon],
                          zoom
                        )
                      }
                    },
                  }}
                >
                  <Popup>
                    <MarkerFacilityCard facility={facility} />
                  </Popup>
                </Marker>
              ))}
            </>
          )}
        </MapContainer>
      )
    }, [selectedProperty])
  }

  return (
    <MapLayout>
      <Seo title="地図で物件を探す" />
      <MapHeader>
        <Stack direction={`row`}>
          <MapSearchBox map={map} />
          <Spacer />
          {map && <FlyToLocationButton map={map} />}
        </Stack>
      </MapHeader>
      <Box position={`absolute`} w={`100%`} top={0} zIndex={0}>
        {displayMap}
      </Box>
      <Stack
        bg={`blackAlpha.300`}
        position={`absolute`}
        top={24}
        left={0}
        h={`80vh`}
        shadow={`md`}
        spacing={1}
      >
        <AreaMenu
          areas={areas}
          map={map}
          open={openAreaMenu}
          setOpen={() => {
            setOpenSpotMenu(false)
            setOpenAreaMenu(!openAreaMenu)
          }}
        />
        {selectedProperty ? (
          <SelectedPropertySpotMenu
            facilities={
              properties.filter(({ node: pr }) => pr.id === selectedProperty)[0]
                .node.facilities
            }
            map={map}
            open={openSpotMenu && !!selectedProperty}
            setOpen={() => {
              setOpenAreaMenu(false)
              setOpenSpotMenu(!openSpotMenu)
            }}
          />
        ) : (
          <SpotMenu
            facilities={facilities}
            map={map}
            open={openSpotMenu}
            setOpen={() => {
              setOpenAreaMenu(false)
              setOpenSpotMenu(!openSpotMenu)
            }}
          />
        )}
      </Stack>
      {map && <ZoomInOut map={map} />}
      <AnimatePresence>
        {selectedProperty && (
          <PropertyPane
            property={
              properties.filter(({ node: pr }) => pr.id === selectedProperty)[0]
                .node
            }
            properties={properties}
            setSelectedProperty={setSelectedProperty}
          />
        )}
      </AnimatePresence>
    </MapLayout>
  )
}
