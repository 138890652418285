import {
  Heading,
  Stack,
  Image,
  Text,
  Button,
  Box,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React from "react"
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiBed } from "react-icons/bi"
import { IoLayers } from "react-icons/io5"
import { MdOutlineMeetingRoom, MdOutlineSpaceDashboard } from "react-icons/md"

type Props = {
  property: {
    id: string
    slug: string
    propertyName: string
    propertyLocation: {
      lat: number
      lon: number
    }
    propertyCategory: {
      name: string
    }
    images: {
      file: {
        url: string
      }
    }[]
    layout: string
    comment: {
      comment: string
    }
    adress: string
    transportation: string
    price: number
    structure: string
    buildingSpaceSqm: number
    facilities: {
      id: string
      name: string
      location: {
        lat: number
        lon: number
      }
      description: {
        description: string
      }
      featuredPhoto: {
        file: {
          url: string
        }
      }
    }[]
  }
}

export const MarkerPropertyCard: React.FC<Props> = ({ property }) => {
  const REGEX_STRUCTURE = /(\d階建)/
  const parsedStructure = REGEX_STRUCTURE.exec(property.structure)
  const jsonFavorites: any = localStorage.getItem("favorites")
  const favorites = jsonFavorites ? JSON.parse(jsonFavorites) : null

  const isFavorited = favorites
    ? favorites.properties.includes(property.id)
    : false

  const addToFavorite = () => {
    if (favorites) {
      if (!isFavorited) {
        localStorage.setItem(
          "favorites",
          JSON.stringify({
            properties: favorites.properties.concat([property.id]),
          })
        )
      }
    } else {
      localStorage.setItem(
        "favorites",
        JSON.stringify({ properties: [property.id] })
      )
    }
  }

  return (
    <Stack w={`301px`} spacing={0}>
      <Box position={`relative`}>
        <Image
          src={property.images[0].file.url}
          roundedTop={`10px`}
          w={`301px`}
          h={`200px`}
          objectFit={`cover`}
        />
        <Box position={`absolute`} bottom={2} left={4}>
          {isFavorited ? (
            <Button
              isDisabled={isFavorited}
              onClick={addToFavorite}
              color={`white`}
              bg={`transparent`}
              p={0}
              _disabled={{ color: "white" }}
              _hover={{ opacity: 0.9 }}
              size={`sm`}
            >
              <AiFillHeart size={30} />
            </Button>
          ) : (
            <Button
              isDisabled={isFavorited}
              onClick={addToFavorite}
              color={`white`}
              bg={`transparent`}
              p={0}
              _hover={{ opacity: 0.9 }}
              size={`sm`}
            >
              <AiOutlineHeart size={30} />
            </Button>
          )}
        </Box>
      </Box>

      <Stack p={4}>
        <Heading fontSize={14} color={`blackAlpha.700`}>
          {property.propertyName}
        </Heading>
        <Stack direction={`row`} align="center" spacing={4}>
          <Stack direction={`row`} align="center" spacing={1}>
            <Box color={`blackAlpha.600`}>
              <MdOutlineMeetingRoom size={18} />
            </Box>
            <Text fontSize={12}>{property.layout}</Text>
          </Stack>
          <Stack direction={`row`} align="center" spacing={1}>
            <Box color={`blackAlpha.600`}>
              <MdOutlineSpaceDashboard size={18} />
            </Box>
            <Text fontSize={12}>{property.buildingSpaceSqm}m2</Text>
          </Stack>
          <Stack direction={`row`} align="center" spacing={1}>
            <Box color={`blackAlpha.600`}>
              <IoLayers size={18} />
            </Box>
            <Text fontSize={12}>{parsedStructure && parsedStructure[0]}</Text>
          </Stack>
        </Stack>
        <Text fontSize={18} fontWeight={`bold`} color={`blackAlpha.700`}>
          {property.price}万円
        </Text>
        <Button
          onClick={() => navigate(`/properties/${property.slug}`)}
          size={`sm`}
        >
          詳細を見る
        </Button>
      </Stack>
    </Stack>
  )
}
