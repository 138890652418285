import { Box, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { AiFillBank } from "react-icons/ai"
import { GrAtm } from "react-icons/gr"
import { GiParkBench, GiPostOffice } from "react-icons/gi"
import { FaRegHospital } from "react-icons/fa"
import {
  MdOutlineLocalConvenienceStore,
  MdOutlineLocalPharmacy,
} from "react-icons/md"
import { TbBeach } from "react-icons/tb"
import { BiBusSchool } from "react-icons/bi"
import { IoFitnessSharp } from "react-icons/io5"
import { RiGovernmentLine, RiNurseLine } from "react-icons/ri"

const FacilityIcon: React.FC<{ slug: string }> = ({ slug }) => {
  switch (slug) {
    case "bank-atm":
      return <GrAtm size={20} />
    case "hospital-clinic":
      return <FaRegHospital size={20} />
    case "drugstore":
      return <MdOutlineLocalPharmacy size={20} />
    case "park":
      return <GiParkBench size={20} />
    case "beach":
      return <TbBeach size={20} />
    case "station-bus-stop":
      return <BiBusSchool size={20} />
    case "onsen-spa":
      return <IoFitnessSharp size={20} />
    case "school-preschool-daycare":
      return <RiNurseLine size={20} />
    case "supermarket-convenience-store":
      return <MdOutlineLocalConvenienceStore size={20} />
    case "post-office":
      return <GiPostOffice size={20} />
    case "government-office":
      return <RiGovernmentLine size={20} />
    default:
      return <AiFillBank size={20} />
  }
}

export const CategoryBox: React.FC<any> = ({
  text,
  slug,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <Stack
      onClick={() => setSelectedCategory(slug)}
      cursor={selectedCategory !== slug ? "pointer" : "default"}
      flexShrink={0}
      justify="center"
      align="center"
      position="relative"
      p={2}
      pb={4}
    >
      <FacilityIcon slug={slug} />
      <Text fontSize={12} fontWeight={`bold`}>
        {text}
      </Text>
      {selectedCategory === slug && (
        <Box position={`absolute`} bottom={0} w={6} borderBottom={`2px`}></Box>
      )}
    </Stack>
  )
}
