import { Button } from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, { useState } from "react"

export const FlyToLocationButton: React.FC<any> = ({ map }) => {
  const ZOOM_LEVEL = 16
  const [flying, setFlying] = useState<boolean>(false)

  return (
    <Button
      as={motion.button}
      bg={`black`}
      color={`white`}
      rounded={0}
      initial={{ opacity: 0, x: 400 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      whileHover={{
        scale: 1.2,
        transition: { type: "spring", stiffness: 100, duration: 0.1 },
      }}
      fontSize={14}
      isLoading={flying}
      _hover={{}}
      onClick={() => {
        setFlying(true)

        map.locate().on("locationfound", function (ev: any) {
          map.flyTo(ev.latlng, ZOOM_LEVEL).on("moveend", function () {
            setFlying(false)
          })
        })
      }}
    >
      現在位置を表示
    </Button>
  )
}
