import { Heading, Stack, Image } from "@chakra-ui/react"
import React from "react"

type Props = {
  facility: {
    id: string
    name: string
    description: {
      description: string
    }
    featuredPhoto: {
      file: {
        url: string
      }
    }
    location: {
      lat: number
      lon: number
    }
  }
}

export const MarkerFacilityCard: React.FC<Props> = ({ facility }) => {
  return (
    <Stack w={`301px`} spacing={0}>
      <Image
        src={facility.featuredPhoto.file.url}
        roundedTop={`10px`}
        w={`301px`}
        h={`200px`}
        objectFit={`cover`}
      />
      <Stack p={4}>
        <Heading fontSize={14} color={`blackAlpha.700`}>
          {facility.name}
        </Heading>
      </Stack>
    </Stack>
  )
}
