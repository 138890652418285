/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Box } from "@chakra-ui/react"
import React, { ReactNode } from "react"

export const MapLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box
      as={`main`}
      h={`100vh`}
      w={`100%`}
      bg={`blackAlpha.50`}
      position={`relative`}
    >
      {children}
    </Box>
  )
}
